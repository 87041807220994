<template>
  <div class="home">
    <Card>
      <Form :model="searchForm"
            :label-width="120"
            :rules="ruleValidate"
            inline>
        <FormItem label="文件名称">
          <Input name="wjm"
                 v-model="searchForm.wjm"
                 placeholder="文件名称"
                 @on-change="(event) => this.handleChangeOption('文件名称', 'string', 'wjm', event.target.value)" />
        </FormItem>
        <!-- <FormItem label="日期">
          <DateRange v-model="searchForm.bgq"
                     @change="(value) => this.handleChangeOption('日期', 'date', 'bgq', value)"></DateRange>
        </FormItem>
        <FormItem label="报告类型"
                  prop="bglx">
          <RadioGroup v-model="searchForm.bglx"
                      @on-change="(data) => this.handleChangeValue('报告类型', 'radio', 'bglx', data)">
            <Radio :label="item.id"
                   v-for="item in radioList.bglx"
                   :key="item.id">{{ item.name }}</Radio>
          </RadioGroup>
        </FormItem> -->
        <FormItem label="大类型">
          <CheckboxGroupAll v-model="searchForm.yjfl"
                            @change="(data) => this.handleChangeSelect('大类型', 'list', 'yjfl', data)"
                            :list="checkboxList.yjfl"
                            :checkboxList="checkboxAllList.yjfl"
                            :tree="false">
          </CheckboxGroupAll>
        </FormItem>
          <Button class="searchBtnLog" data-id="1" type="primary"
                  @click="handleSearch">查询</Button>
        </FormItem>
      </Form>
    </Card>
    <div class="tagbox">
      <template v-if="Object.keys(selectedList).length !== 0">
        已选条件：
        <template v-for="item in selectedList">
          <span v-if="item !== null"
                :key="item.formItem">

            <Tag
                v-if="item"
                type="border"
                closable
                @on-close="() => handleCloseAll(item)"
            >
                <span></span>
                <span
                    v-for="(name, index) in item.data"
                    :key="index"
                >{{index>0?',':''}}{{ name }}</span>
            </Tag>
            <span v-else>
                <Tag
                    v-for="(name, index) in item.data"
                    :key="name"
                    type="border"
                    closable
                    @on-close="() => handleClose(index, item)"
                >
                    <span v-if="item">{{item.title}}:</span>
                    <span>{{name}}</span>
                </Tag>
            </span>
          </span>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import tagBoxMixin from '@/views/share/tagBoxMixin';
import dateHandleMixin from '@/views/share/dateHandleMixin';
import { get_enterprise_dim_bglxbhjb, get_enterprise_dim_nbdfx } from '@/api/houseEnterprise';
export default {
  name: 'SearchPanel',
  components: {},
  mixins: [tagBoxMixin, dateHandleMixin],
  data () {
    return {
      searchForm: {
        // bglx: '', // 报告类型
        yjfl: [], // 公告类型
        // bgq: ['', ''], // 日期
        wjm: '', //文件名
        size: 10,
        current: 1,
      },
      radioList: {
        // bglx: [],
      },
      checkboxList: {
        yjfl: [{
          id: '运营',
          name: '运营'
        }, {
          id: '销售',
          name: '销售'
        }, {
          id: '项目',
          name: '项目'
        }, {
          id: '土地储备',
          name: '土地储备'
        }],
      },
      checkboxAllList: {
        yjfl: [],
      },
      selectedList: {}, //已选条件
      ruleValidate: {
        // bglx: [{ required: true, message: ' ', trigger: 'change' }],
      },
    };
  },
  mounted () {
    // this.init();
    this.handleSearch()
  },
  methods: {
    init () {
      // this.getRadioList();
      // this.getCheckboxList('yjfl');
    },
    getRadioList () {
      get_enterprise_dim_bglxbhjb()
        .then((data) => {
          this.radioList.bglx = data;
          this.searchForm.bglx = data[0].id;
          this.handleChangeValue('报告类型', 'radio', 'bglx', data[0].id);
        })
        .catch((e) => {

        });
    },
    getCheckboxList (type) {
      let requestType = {
        yjfl: get_enterprise_dim_nbdfx,
      };
      requestType[type]()
        .then((data) => {
          this.handleCheckbox(data, type);
        })
        .catch((e) => {

        });
    },
    handleCloseAll(item) {
        const { choosedType, data, formItem: type } = item;

        let selectedList = Object.assign({}, this.selectedList);
        selectedList[type] = null;
        this.selectedList = selectedList;
        this.searchForm[type] = [];
    },
    handleCheckbox (data, name) {
      let list = [];
      data.forEach((item, index) => {
        list.push({
          id: item,
          name: item,
        });
      });
      this.checkboxList[name] = list;
      this.checkboxAllList[name] = data;
    },
    handleChangeValue (name, type, formItem, value) {
      const checkedValue = value;
      const radioList = this.radioList[formItem];
      let list = [];
      for (let i = 0, len = radioList.length; i < len; i++) {
        if (radioList[i].id === checkedValue) {
          list.push(radioList[i].name);
          break;
        }
      }
      this.handleChangeOption(name, type, formItem, list);
    },
    handleChangeSelect (name, type, formItem, value) {
      const checkedList = value;
      const checkboxList = this.checkboxList[formItem];
      let list = [];
      checkedList.map((item) => {
        for (let i = 0, len = checkboxList.length; i < len; i++) {
          if (item === checkboxList[i].id) {
            list.push(checkboxList[i].name);
            break;
          }
        }
      });

      this.handleChangeOption(name, type, formItem, list);
    },
    handleSearch () {
      this.$emit('submit', this.searchForm);
    },
  },
};
</script>
