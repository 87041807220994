<template>
	<div class="home">
		<dataplat-search @submit="handleSubmit" ref="dataplatSearch"></dataplat-search>
		<dataplat-content :columns="tableColumns" :list="tableList" :total="total" @changePage="handleChangePage"
			@changePageSize="handleChangePageSize"></dataplat-content>
	</div>
</template>

<script>
	import SearchPanel from "./notice/statistics/SearchPanel"
	import ContentPanel from "./notice/statistics/ContentPanel"
	import {
		post_enterprise_qygg_nbwj
	} from '@/api/houseEnterprise'
	export default {
		name: 'statistics',
		components: {
			'dataplat-search': SearchPanel,
			'dataplat-content': ContentPanel,
		},
		computed: {
			gpdm() {
				return this.$store.state.reHouseEnterprise.selectedCompantGpmc
			}
		},
		watch: {
			gpdm(newVal, oldVal) {
				this.$refs.dataplatSearch.handleSearch()
			}
		},
		data() {
			return {
				tableColumns: [],
				tableList: [],
				total: 0,
				showChart: false,
				searchForm: {}
			}
		},
		methods: {
			handleChangePage(page) {
				this.searchForm.current = page;
				this.handleSubmit(this.searchForm);
			},
			handleChangePageSize(page) {
				this.searchForm.size = page;
				this.handleSubmit(this.searchForm);
			},
			handleSubmit(params) {
				const searchForm = Object.assign(params, {
					gpdm: this.gpdm // 股票代码
				});
				post_enterprise_qygg_nbwj(searchForm).then(data => {
					this.tableColumns = [{
						title: '时间',
						align: 'center',
						key: 'bgq',
						ellipsis: true,
						tooltip: true,

					}, {
						title: '一级分类',
						align: 'center',
						ellipsis: true,
						tooltip: true,
						key: 'yjfl',
					}, {
						title: '二级分类',
						align: 'center',
						ellipsis: true,
						tooltip: true,
						key: 'lx',
					}, {
						title: '文件',
						align: 'center',
						ellipsis: true,
						tooltip: true,

						key: 'wjm',

						render: (h, params) => {
							return h('a', {
								attrs: {
									class: "btn-detail",
									title: params.row.wjm,
								},
								on: {
									click: () => {
										this.$btnSysLog(1,1)
										params.row.url && window.open(params.row.url,
											'_blank')
									}
								}
							}, params.row.wjm)
						}
					}];
					//url	string url
					data.records.forEach(item => {
						item.bgq = `${item.sjkssj}-${item.sjjzsj}`
					})
					this.total = data.total;
					this.tableList = data.records;
				}).catch(e => {

				});
			}
		}
	}
</script>
