<template>
<div>
    <Table tooltip-theme="light" border :columns="columns" :data="list" class="table-box"></Table>
    <Page v-if="total !== 0" class="pagetion" :total="total" prev-text="上一页" next-text="下一页" show-elevator show-sizer @on-change="handleChangePage" />
</div>
</template>

<script>
export default {
    name: 'contentPanel',
    props: ['columns', 'list', 'total'],
    data() {
        return {}
    },
    methods: {
        handleChangePage(page) {
            this.$emit('changePage', page);
        }
    }

}
</script>
