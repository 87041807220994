<template>
	<div class="home">
		<dataplat-search @submit="handleSubmit" ref="dataplatSearch"></dataplat-search>
		<dataplat-content :current="searchForm.page" v-if="total !== 0" :list="tableList" :total="total"
			@changePage="handleChangePage" @changePageSize="handleChangePageSize"></dataplat-content>
	</div>
</template>

<script>
	import SearchPanel from "./notice/enterpriseAnnouncement/SearchPanel"
	import ListPanel from "./notice/enterpriseAnnouncement/ListPanel"
	import {
		post_enterprise_qygg_gg
	} from '@/api/houseEnterprise'
	export default {
		name: 'enterpriseAnnouncement',
		components: {
			'dataplat-search': SearchPanel,
			'dataplat-content': ListPanel,
		},
		computed: {
			gpdm() {
				return this.$store.state.reHouseEnterprise.selectedCompantGpmc
			}
		},
		watch: {
			gpdm(newVal, oldVal) {
				this.$refs.dataplatSearch.handleSearch()
			}
		},
		data() {
			return {
				searchForm: {
					size: 10,
				},
				tableList: [],
				total: 0
			}
		},
		mounted() {
			const that = this;
			setTimeout(function() {
				if (that.gpdm.length !== 0) {
					that.$refs.dataplatSearch.handleSearch();
				}
			}, 500);
		},
		methods: {
			handleChangePage(page) {
				this.searchForm.current = page;
				this.handleSubmit(this.searchForm);
			},
			handleChangePageSize(page) {
				this.searchForm.size = page;
				this.handleSubmit(this.searchForm);
			},
			handleSubmit(params) {
				const searchForm = Object.assign(params, {
					gpdm: this.gpdm // 股票代码
				});
				this.searchForm = searchForm;
				post_enterprise_qygg_gg(searchForm).then(data => {
					this.tableList = data.records;
					this.total = data.total;
				}).catch(e => {

				});
			}
		}
	}
</script>
