<template>
	<div class="right-box">
		<Tabs value="enterpriseAnnouncement" class="tab-box">
			<TabPane label="企业公告" name="enterpriseAnnouncement">
				<dataplat-enterprise-announcement></dataplat-enterprise-announcement>
			</TabPane>
			<TabPane label="年报统计信息" name="statistics">
				<dataplat-statistics></dataplat-statistics>
			</TabPane>
		</Tabs>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EnterpriseAnnouncement from "./components/EnterpriseAnnouncement"
	import Statistics from "./components/Statistics"
	import dataReports from '@/mixins/dataReports'
	export default {
		name: 'notice',
		mixins: [dataReports],
		components: {
			'dataplat-enterprise-announcement': EnterpriseAnnouncement,
			'dataplat-statistics': Statistics,
		}
	}
</script>
