<template>
  <div class="home">
    <Card>
      <Form :model="searchForm" :label-width="80" inline>
        <FormItem label="标题">
          <Input
            name="ggbt"
            v-model="searchForm.ggbt"
            placeholder="标题"
            @on-change="
              (event) =>
                this.handleChangeOption(
                  '标题',
                  'string',
                  'ggbt',
                  event.target.value
                )
            "
          />
        </FormItem>
        <FormItem label="日期">
          <DateRange
            v-model="searchForm.ggrq"
            @change="
              (value) => this.handleChangeOption('日期', 'date', 'ggrq', value)
            "
          ></DateRange>
        </FormItem>
        <div>
          <FormItem label="公告类型">
            <CheckboxGroupAll
              v-model="searchForm.gglx"
              @change="
                (data) =>
                  this.handleChangeValue('公告类型', 'list', 'gglx', data)
              "
              :list="checkboxList.gglx"
              :checkboxList="checkboxAllList.gglx"
              :tree="false"
            ></CheckboxGroupAll>
          </FormItem>
          <Button class="searchBtnLog" data-id="1" type="primary" @click="handleSearch">查询</Button>
        </div>
      </Form>
    </Card>
    <div class="tagbox">
      <template v-if="Object.keys(selectedList).length !== 0">
        已选条件：
        <template v-for="item in selectedList">
          <span v-if="item !== null" :key="item.formItem">
            <Tag type="border" closable @on-close="() => handleCloseAll(item)">
              <span v-for="(name, index) in item.data" :key="index"
                >{{ index > 0 ? "," : "" }}{{ name }}</span
              >
            </Tag>
          </span>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { get_enterprise_dim_gglx } from "@/api/houseEnterprise";
import tagBoxMixin from "@/views/share/tagBoxMixin";
import dateHandleMixin from "@/views/share/dateHandleMixin";
import { checkDate } from "@/utils/index";
export default {
  name: "SearchPanel",
  components: {},
  mixins: [tagBoxMixin, dateHandleMixin],
  mounted() {
    this.init();
  },
  data() {
    return {
      checkboxList: {
        gglx: [],
      },
      checkboxAllList: {
        gglx: [],
      },
      searchForm: {
        ggbt: "", // 公告标题
        ggrq: ["", ""], // 公告日期
        gglx: [], // 公告类型
        size: 10,
        current: 1,
      },
      selectedList: {}, //已选条件
    };
  },
  methods: {
    init() {
      this.getCheckboxList("gglx");
    },
    getCheckboxList(type) {
      get_enterprise_dim_gglx()
        .then((data) => {
          this.handleCheckbox(data, type);
        })
        .catch((e) => {

        });
    },
    handleCheckbox(data, name) {
      let list = [];
      data.forEach((item) => {
        list.push(item.id);
      });
      this.checkboxList[name] = data.map((item) => {
        item.title = item.name;
        return item;
      });
      this.checkboxAllList[name] = list;
    },
    handleChangeValue(name, type, formItem, value) {
      const checkedList = value;
      const checkboxList = this.checkboxList[formItem];
      let list = [];
      checkedList.map((item) => {
        for (let i = 0, len = checkboxList.length; i < len; i++) {
          if (item === checkboxList[i].id) {
            list.push(checkboxList[i].name);
            break;
          }
        }
      });
      this.handleChangeOption(name, type, formItem, list);
    },
    handleCloseAll(item) {
      const { choosedType, data, formItem: type } = item;

      let selectedList = Object.assign({}, this.selectedList);
      selectedList[type] = null;
      this.selectedList = selectedList;
      this.searchForm[type] = [];
    },
    handleSearch() {
      const { ggrq } = this.searchForm;
      if (!checkDate(ggrq[0], ggrq[1])) {

          this.$msg.error({ type: "remind", code: 4015 });

        return;
      }
      let searchForm = this.handleDateRule(
        ggrq,
        "ggrq",
        "日期",
        this.searchForm
      );
      if (searchForm === null) {
        return;
      }
      this.$emit("submit", searchForm);
    },
  },
};
</script>
