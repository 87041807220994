<template>
    <div>
        <div v-for="item in list" :key="item.id" class="list-item" @click="toDetail(item)">
            <div class="left">
                <Icon type="md-arrow-dropright" /><span class="gglx" v-if="item.gglxmc">{{ item.gglxmc }}</span
                >{{ item.bt }}
            </div>
            <div class="right">{{ item.ggrq }}</div>
        </div>
        <Page
            v-if="total !== 0"
            :current="current"
            class="pagetion"
            :total="total"
            prev-text="上一页"
            next-text="下一页"
            show-elevator
            show-sizer
            @on-change="handleChangePage"
        />
    </div>
</template>
<script>
export default {
    name: 'listPanel',
    props: {
        list: Array,
        total: Number,
        current: {
            type: [String, Number],
            default: 1,
        },
    },
    watch: {
        current(newValue, oldValue) {
            this.current = newValue;
        },
    },
    data() {
        return {};
    },
    methods: {
        handleChangePage(page) {
            this.$emit('changePage', page);
        },
        toDetail(data) {
            window.open(data.url);
        }
    },
};
</script>

<style scoped lang="scss">
.list-item {
    display: flex;
    padding: 12px;
    border-bottom: 1px dashed #d6d7d9;
    justify-content: space-between;
    cursor: pointer;
    .right {
        color: #999;
    }
    .gglx {
        background: rgba(246, 250, 255, 1);
        padding: 2px 6px;
        color: #1562d6;
        border-radius: 2px;
        border: 1px solid rgba(21, 98, 214, 1);
        display: inline;
        margin-right: 10px;
    }
}
</style>
